import React, { useState, useEffect } from "react";
import Pagination from "../Analysis/Pagination/Pagination";
import Base from "../../../components/Base/Base";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Select, MenuItem } from "@mui/material"; 
import { backendUri } from "../../../constants";

const UserListTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ProjectId, setProjectId] = useState(
    location?.state?.selectedProject?.ProjectId ?? ""
  );

  const [ProjectName, setProjectName] = useState(
    location?.state?.selectedProject?.ProjectName ?? ""
  );

  const [displaytable, setDisplayTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [statusFilter, setStatusFilter] = useState("Active"); 
  const tableDataPerPage = 5;
  const dummyHeaders = ["Name", "Role", "Email ID" ,"Status",  "Last login on"];

  const fetchUserData = (status) => {
    const myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const apiUrl = `${backendUri}/api/get/all/report${status ? `?Status=${status}` : ""}`;

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          const users = result.data.Users || result.data; 
          setDisplayTable(users || []);  
        } else {
          setDisplayTable([]);  
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setDisplayTable([]); 
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUserData(statusFilter); 
  }, [statusFilter]); 

  const handleRowClick = (row) => {
    navigate(`/UserDetail/${row.Id}`, { state: { row } });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const getRoleName = (roleId) => {
    switch (roleId) {
      case 1:
        return "Admin";
      case 2:
        return "Manager";
      case 3:
        return "Team Member";
      default:
        return "Unknown";
    }
  };

  const getStatusStyle = (status) => {
    return {
      backgroundColor: status === "Active" ? "#d4edda" : "#f8d7da",
      color: status === "Active" ? "#155724" : "#721c24",
      borderRadius: "16px",
      padding: "4px 9px",
      display: "inline-block",
      fontWeight: "bold",
    };
  };


  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "?";
  };

  return (
    <Base>
      <div className="dashboard-content-analysis">
        <Button
          variant="contained"
          onClick={handleBack}
          className="back-button"
          style={{ marginBottom: '10px' }}
        >
          Back
        </Button>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div style={{ fontSize: '20px', fontWeight: '600', marginBottom: '10px' }}>
            {ProjectName} Project User List Table
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="statusFilter">Filter by Status: </label>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              id="statusFilter"
              style={{ minWidth: '150px', marginLeft: '10px' }}
            >
              <MenuItem value="" >All</MenuItem> 
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">Inactive</MenuItem>
            </Select>
          </div>
        </div>

        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <table id="Table">
            <thead>
              <tr id="Table_headings">
                {dummyHeaders.map((header, index) => (
                  <th className="Table_heading" key={index}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displaytable.length > 0 ? (
                displaytable.slice(currentPageIndex * tableDataPerPage, (currentPageIndex + 1) * tableDataPerPage).map((row, rowIndex) => (
                  <tr className="Table_row" key={rowIndex}>
                    {/* <td className="Table_values">
                      <div className="Avatar_table_value">
                        <div className="Table_values_initial_circle">
                          {getInitial(row.FirstName)}
                        </div>
                        {row.FirstName}
                      </div>
                    </td> */}
                     <td className="Table_values">
                    <div className="Avatar_table_value">
                      {/* Replace image with Initial inside a circle */}
                      <div className="Table_values_initial_circle">
                        {getInitial(row.FirstName)}
                      </div>
                      <span
                        className="Table_value Table_values_link"
                        // style={{ cursor: "pointer", color: "blue" }}
                        // onClick={() => handleRowClick(row)}
                      >
                        {row.FirstName}
                      </span>
                      {/* <img className="AL_Table_values_rightarrow" src={rightarrow} alt="arrow" /> */}
                    </div>
                  </td>
                    <td className="Table_values Table_value">{getRoleName(row.RoleId)}</td>
                    <td className="Table_values Table_value">{row.UserEmailId}</td>
                    <td className="Table_values Table_value">
                      <span style={getStatusStyle(row.Status)}>{row.Status}</span>
                    </td>
                    <td className="Table_values Table_value">{row.LastLogin}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td align="center" colSpan={dummyHeaders.length}>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <Pagination
          tableData={displaytable}
          tableDataPerPage={tableDataPerPage}
          activePageIndex={currentPageIndex}
          updateCurrentPageIndex={setCurrentPageIndex}
          numberOfPages={Math.ceil(displaytable.length / tableDataPerPage)}
          totalRecords={displaytable.length}
        />
      </div>
    </Base>
  );
};

export default UserListTable;
