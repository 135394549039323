// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .css-5c1adp-MuiContainer-root{
    overflow: scroll !important;
} */
.css-1lym95h-MuiGrid-root{
    height: 300px !important;
 }
`, "",{"version":3,"sources":["webpack://./src/screens/Login/components/Reports/Reports.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,wBAAwB;CAC3B","sourcesContent":["/* .css-5c1adp-MuiContainer-root{\n    overflow: scroll !important;\n} */\n.css-1lym95h-MuiGrid-root{\n    height: 300px !important;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
