import React, { useState, useEffect, createRef } from "react";
import "./Analysis.css";
import ellipsis from "../../../assets/images/Option-1.svg";
import viewtooltip from "../../../assets/images/View-roles.svg";
import sortarrow from "../../../assets/images/Sort.svg";
import rightarrow from "../../../assets/images/Action.svg";
import Tippy from "@tippyjs/react";
import Pagination from "./Pagination/Pagination";
import Base from "../../../components/Base/Base";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material"; 
import { backendUri } from "../../../constants";

const UserListTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ProjectId, setProjectId] = useState(
    location?.state?.selectedProject?.ProjectId ?? ""
  );

  const [ProjectName, setProjectName] = useState(
    location?.state?.selectedProject?.ProjectName ?? ""
  );

  const [displaytable, setDisplayTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const tableDataPerPage = 4;
  const dummyHeaders = ["Name", "Role", "Phases", "Status", "Last login on"];

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`${backendUri}/api/report/project?ProjectId=${ProjectId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setDisplayTable(result.data); 
        } else {
          setDisplayTable([]); 
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  }, [ProjectId]);

  const handleRowClick = (row) => {
    navigate(`/UserDetail/${row.Id}`, { state: { row } });
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Role Mapping Function
  const getRoleName = (roleId) => {
    switch (roleId) {
      case 1:
        return "Admin";
      case 2:
        return "Manager";
      case 3:
        return "Team Member";
      default:
        return "Unknown";
    }
  };

  // Status Style Function
  const getStatusStyle = (status) => {
    return {
      backgroundColor: status === "Active" ? "#d4edda" : "#f8d7da",
      color: status === "Active" ? "#155724" : "#721c24",
      borderRadius: "16px",
      padding: "4px 9px",
      display: "inline-block",
      fontWeight: "bold",
    };
  };

  // Function to get the first letter of the name
  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "?";
  };

  return (
    <Base>
      <div className="dashboard-content-analysis">
        <Button
          variant="contained"
          onClick={handleBack}
          className="back-button"
          style={{ marginBottom: '10px' }}
        >
          Back
        </Button>
        <div style={{ fontSize: '20px', fontWeight: '600' }}>
          {ProjectName} Project User List Table
        </div>

        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <table id="Table">
            <thead>
              <tr id="Table_headings">
                {dummyHeaders.map((header, index) => (
                  <th className="Table_heading" key={index}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displaytable.slice(currentPageIndex * tableDataPerPage, (currentPageIndex + 1) * tableDataPerPage).map((row, rowIndex) => (
                <tr className="Table_row" key={rowIndex}>
                  <td className="Table_values">
                    <div className="Avatar_table_value">
                      {/* Replace image with Initial inside a circle */}
                      <div className="Table_values_initial_circle">
                        {getInitial(row.FirstName)}
                      </div>
                      <span
                        className="Table_value Table_values_link"
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => handleRowClick(row)}
                      >
                        {row.FirstName}
                      </span>
                      <img className="AL_Table_values_rightarrow" src={rightarrow} alt="arrow" />
                    </div>
                  </td>
                  <td className="Table_values Table_value">{getRoleName(row.RoleId)}</td>
                  <td className="Table_values Table_value">
                    <div className="AL_Tooltip">
                      {row.Phases.length > 1 ? row.Phases.length + " Phases" : row.Phases[0]}
                      {row.Phases.length > 1 && (
                        <Tippy
                          content={
                            <div className="AL_Tooltip_icon AL_Tooltip_content AL_T_rights">
                              {row.Phases.map((phase, i) => (
                                <div key={i}>
                                  {phase}
                                  {i < row.Phases.length - 1 && <hr />}
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <img className="AL_Tooltip_icon" src={viewtooltip} alt="view" />
                        </Tippy>
                      )}
                    </div>
                  </td>
                  <td className="Table_values Table_value">
                    <span style={getStatusStyle(row.Status)}>{row.Status}</span>
                  </td>
                  <td className="Table_values Table_value">{row.LastLogin}</td>
                </tr>
              ))}
              {displaytable.length === 0 && (
                <tr>
                  <td align="center" colSpan={dummyHeaders.length}>
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <Pagination
          tableData={displaytable}
          tableDataPerPage={tableDataPerPage}
          activePageIndex={currentPageIndex}
          updateCurrentPageIndex={setCurrentPageIndex}
          numberOfPages={Math.ceil(displaytable.length / tableDataPerPage)}
          totalRecords={displaytable.length}
        />
      </div>
    </Base>
  );
};

export default UserListTable;
