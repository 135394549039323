import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, MenuItem, Select, Container, Button, Grid } from '@mui/material';
import { Line, Bar, Pie, Doughnut, Radar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement, RadialLinearScale } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import Base from '../../../../components/Base/Base';
import './Reports.css';
import { backendUri } from "../../../../constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale
);

const Reports = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [chartType, setChartType] = useState('line');
  const [analysisData, setAnalysisData] = useState(null);
  const [activitiesData, setActivitiesData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    const reportType = activeTab === 0 ? 'ANALYSIS' : 'ACTIVITY';

    setIsLoading(true);
    setErrorMessage('');

    const myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${backendUri}/api/report/in-demand-phases?reportType=${reportType}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result.status) {
          const labels = activeTab === 0 
            ? result.data.map((item) => item.Month) 
            : result.data.map((item) => item.PhaseName);
          
          const data = activeTab === 0 
            ? result.data.map((item) => item.totalUserCount) 
            : result.data.map((item) => item.phaseCount);

          const chartData = {
            labels,
            datasets: [{
              label: activeTab === 0 ? 'Total User Count' : 'Phase Count',
              data,
              backgroundColor: activeTab === 0 
                ? 'rgba(75, 192, 192, 0.2)' 
                : ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)'],
              borderColor: activeTab === 0 
                ? 'rgba(75, 192, 192, 1)' 
                : ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
              borderWidth: 1,
            }],
          };

          if (activeTab === 0) {
            setAnalysisData(chartData);
          } else {
            setActivitiesData(chartData);
          }
        } else {
          throw new Error(result.message || 'Error fetching data');
        }
        setErrorMessage('');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setErrorMessage('Failed to load data. Please try again later.');
        if (activeTab === 0) {
          setAnalysisData([]);
        } else {
          setActivitiesData([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleExportReport = () => {
    alert(`Exporting report for ${activeTab === 0 ? 'Analysis' : 'Activities'} chart!`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const renderChart = () => {
    const data = activeTab === 0 ? analysisData : activitiesData;

    if (isLoading) {
      return <div>Loading data...</div>;
    }

    if (errorMessage) {
      return <div>{errorMessage}</div>;
    }

    if (!data || !Array.isArray(data.datasets) || data.datasets.length === 0) {
      return <div>No data available for this chart.</div>;
    }

    switch (chartType) {
      case 'line':
        return <Line data={data} height={300} width={600} />;
      case 'bar':
        return <Bar data={data} height={300} width={600} />;
      case 'pie':
        return <Pie data={data} height={300} width={600} />;
      case 'doughnut':
        return <Doughnut data={data} height={300} width={600} />;
      case 'radar':
        return <Radar data={data} height={300} width={600} />;
      default:
        return <Line data={data} height={300} width={600} />;
    }
  };

  return (
    <Base>
      <Container>
        <Box sx={{ width: '100%', marginTop: 4, padding: 3, backgroundColor: '#f4f6f8', borderRadius: '8px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBack}
            sx={{ position: 'absolute', top: 16, left: 16 }}
            style={{ display: 'contents' }}
          >
            Back
          </Button>

          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Analysis" />
            <Tab label="Activities" />
          </Tabs>

          <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <Button variant="contained" color="primary" onClick={handleExportReport}>
              Export Report
            </Button> */}

            <Select
              value={chartType}
              onChange={handleChartTypeChange}
              variant="outlined"
              sx={{ minWidth: 150 }}
            >
              <MenuItem value="line">Line Chart</MenuItem>
              <MenuItem value="bar">Bar Chart</MenuItem>
              <MenuItem value="pie">Pie Chart</MenuItem>
              <MenuItem value="doughnut">Doughnut Chart</MenuItem>
              <MenuItem value="radar">Radar Chart</MenuItem>
            </Select>
          </Box>

          <Box sx={{ marginTop: 4 }}>
            <Grid container justifyContent="center">
              {renderChart()}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Base>
  );
};

export default Reports;
