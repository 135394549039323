

// import { useLocation, useParams, useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import Base from "../../../../components/Base/Base";
// import { Button, Card, Typography, Box, Grid } from "@mui/material";
// import { Bar, Pie } from "react-chartjs-2";
// import "./UserDetail.css";
// import { backendUri } from "../../../../constants";

// const DetailsPage = () => {
//   const location = useLocation();
//   const { name } = useParams();
//   const row = location.state?.row;
//   const navigate = useNavigate();

//   const [analysisData, setAnalysisData] = useState([]);
//   const [activityData, setActivityData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (row?.Id) {
//       const fetchAnalysisData = async () => {
//         setLoading(true);
//         setError(null);
//         const myHeaders = new Headers();
//         myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
//         myHeaders.append("Content-Type", "application/json");
//         const requestOptions = {
//           method: "GET",
//           headers: myHeaders,
//           redirect: "follow",
//         };

//         try {
//           const response = await fetch(
//             `${backendUri}/api/report/analysis?Id=${row.Id}`,
//             requestOptions
//           );
//           const result = await response.json();
//           if (result.status) {
//             setAnalysisData(result.data.analysis);
//             setActivityData(result.data.activity);
//           } else {
//             throw new Error(result.message);
//           }
//         } catch (error) {
//           setError("Failed to load data");
//           console.error(error);
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchAnalysisData();
//     }
//   }, [row?.Id]);

//   const handleBack = () => {
//     navigate(-1);
//   };

//   const getStatusColor = (status) => {
//     return status?.toLowerCase() === "active" ? "#4CAF50" : "#f44336"; // green for Active, red for Inactive
//   };

//   const getRole = (roleId) => {
//     switch (roleId) {
//       case 1:
//         return "Admin";
//       case 2:
//         return "Manager";
//       case 3:
//         return "Team Member";
//       default:
//         return "Unknown";
//     }
//   };

//   const loginData = {
//     labels: analysisData.map((item) => item.Month),
//     datasets: [
//       {
//         label: "Login Analysis",
//         data: analysisData.map((item) => item.percentageAnalysis),
//         backgroundColor: "rgba(75, 192, 192, 0.6)",
//         borderColor: "rgba(75, 192, 192, 1)",
//         borderWidth: 1,
//       },
//     ],
//   };

//   const phaseData = {
//     labels: activityData.map((item) => item.PhaseName),
//     datasets: [
//       {
//         label: "Phase usage",
//         data: activityData.map((item) => item.phaseCount),
//         backgroundColor: [
//           "rgba(255, 99, 132, 0.6)",
//           "rgba(54, 162, 235, 0.6)",
//           "rgba(255, 206, 86, 0.6)",
//           "rgba(75, 192, 192, 0.6)",
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   return (
//     <Base>
//       <div className="dashboard-container">
//         <div className="dashboard-content">
//           <div className="dashboard-header">
//             <Button
//               variant="contained"
//               onClick={handleBack}
//               className="back-button"
//             >
//               Back
//             </Button>
//             <Typography
//               variant="h4"
//               component="div"
//               className="dashboard-name"
//             >
//               {row.FirstName}
//             </Typography>
//             <Typography
//               variant="subtitle2"
//               className="dashboard-role"
//               style={{
//                 marginBottom: "16px",
//                 fontWeight: "bold",
//                 color: "#666",
//               }}
//             >
//               Role: {getRole(row.RoleId)}
//             </Typography>
//           </div>

//           <div className="dashboard-status-login">
//             <Typography
//               variant="subtitle1"
//               className="dashboard-status"
//               style={{
//                 backgroundColor: getStatusColor(row.Status),
//                 padding: "4px 5px",
//                 borderRadius: "12px",
//                 color: "#fff",
//                 display: "inline-block",
//                 marginBottom: "16px",
//               }}
//             >
//               Status: {row.Status}
//             </Typography>
//             <Typography variant="subtitle1" className="dashboard-last-login">
//               Last Login: {row.LastLogin || "N/A"}
//             </Typography>
//           </div>

//           <Card
//             className="dashboard-phases"
//             sx={{ padding: "16px", marginBottom: "24px" }}
//           >
//             <Typography variant="h6" gutterBottom>
//               Phases Assigned:
//             </Typography>
//             {row.Phases && row.Phases.length > 0 ? (
//               <Grid container spacing={2}>
//                 {row.Phases.map((phase, index) => (
//                   <Grid item xs={12} sm={6} md={4} key={index}>
//                     <Card
//                       className="phase-card"
//                       sx={{
//                         padding: "8px",
//                         backgroundColor: "#f0f0f0",
//                         borderRadius: "8px",
//                       }}
//                     >
//                       <Typography variant="body1" className="phase-item">
//                         {phase}
//                       </Typography>
//                     </Card>
//                   </Grid>
//                 ))}
//               </Grid>
//             ) : (
//               <Typography variant="body1">No phases assigned</Typography>
//             )}
//           </Card>

//           <Typography
//             variant="h6"
//             component="div"
//             className="graph-title"
//             sx={{ marginTop: 4, marginBottom: 2 }}
//           >
//             Login Analysis and Phase Usage
//           </Typography>
//           <Grid container spacing={4}>
//             {/* Login Analysis Graph */}
//             <Grid item xs={12} sm={6}>
//               <Card className="graph-card" sx={{ padding: "16px" }}>
//                 <Typography variant="h6" component="div" className="graph-heading">
//                   Login Analysis
//                 </Typography>
//                 <div className="chart-container">
//                   {analysisData.length > 0 ? (
//                     <Bar
//                       data={loginData}
//                       options={{
//                         responsive: true,
//                         maintainAspectRatio: true,
//                         scales: {
//                           y: {
//                             beginAtZero: true,
//                             ticks: {
//                               stepSize: 5,
//                             },
//                           },
//                         },
//                         plugins: {
//                           legend: {
//                             display: true,
//                             position: "top",
//                           },
//                         },
//                       }}
//                     />
//                   ) : (
//                     <Typography
//                       variant="body1"
//                       sx={{
//                         padding: "16px",
//                         textAlign: "center",
//                         color: "#999",
//                         fontStyle: "italic",
//                       }}
//                     >
//                       Login Analysis data not found
//                     </Typography>
//                   )}
//                 </div>
//               </Card>
//             </Grid>

//             {/* Phase Usage Graph */}
//             <Grid item xs={12} sm={6}>
//               <Card className="graph-card" sx={{ padding: "16px" }}>
//                 <Typography variant="h6" component="div" className="graph-heading">
//                   Phase Usage
//                 </Typography>
//                 <div className="chart-container">
//                   {activityData.length > 0 ? (
//                     <Pie
//                       data={phaseData}
//                       options={{
//                         responsive: true,
//                         maintainAspectRatio: true,
//                         plugins: {
//                           legend: {
//                             display: true,
//                             position: "top",
//                           },
//                         },
//                       }}
//                     />
//                   ) : (
//                     <Typography
//                       variant="body1"
//                       sx={{
//                         padding: "16px",
//                         textAlign: "center",
//                         color: "#999",
//                         fontStyle: "italic",
//                       }}
//                     >
//                       Phase Usage data not found
//                     </Typography>
//                   )}
//                 </div>
//               </Card>
//             </Grid>
//           </Grid>
//         </div>
//       </div>
//     </Base>
//   );
// };

// export default DetailsPage;
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Base from "../../../../components/Base/Base";
import { Button, Card, Typography, Box, Grid, CircularProgress } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { formatDistanceToNow, parse } from "date-fns"; // Import parse and formatDistanceToNow
import "./UserDetail.css";
import { backendUri } from "../../../../constants";

const DetailsPage = () => {
  const location = useLocation();
  const { name } = useParams();
  const row = location.state?.row;
  const navigate = useNavigate();

  const [analysisData, setAnalysisData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (row?.Id) {
      const fetchAnalysisData = async () => {
        setLoading(true);
        setError(null);
        const myHeaders = new Headers();
        myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        try {
          const response = await fetch(
            `${backendUri}/api/report/analysis?Id=${row.Id}`,
            requestOptions
          );
          const result = await response.json();
          if (result.status) {
            setAnalysisData(result.data.analysis);
            setActivityData(result.data.activity);
          } else {
            throw new Error(result.message);
          }
        } catch (error) {
          setError("Failed to load data");
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchAnalysisData();
    }
  }, [row?.Id]);

  const handleBack = () => {
    navigate(-1);
  };

  const getStatusColor = (status) => {
    return status?.toLowerCase() === "active" ? "#4CAF50" : "#f44336"; // green for Active, red for Inactive
  };

  const getRole = (roleId) => {
    switch (roleId) {
      case 1:
        return "Admin";
      case 2:
        return "Manager";
      case 3:
        return "Team Member";
      default:
        return "Unknown";
    }
  };

  const formatLastLogin = (lastLogin) => {
    if (!lastLogin) return "N/A"; // Handle empty value

    // Define the date format you're receiving
    const dateFormat = 'dd/MM/yyyy hh:mm a';
    
    // Parse the date using the date-fns 'parse' method
    const lastLoginDate = parse(lastLogin, dateFormat, new Date());

    // Check if the date is valid
    if (isNaN(lastLoginDate.getTime())) {
      return "Invalid date";
    }

    // Return the formatted "time ago" text
    return formatDistanceToNow(lastLoginDate, { addSuffix: true });
  };

  const loginData = {
    labels: analysisData.map((item) => item.Month),
    datasets: [
      {
        label: "Login Analysis",
        data: analysisData.map((item) => item.percentageAnalysis),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const phaseData = {
    labels: activityData.map((item) => item.PhaseName),
    datasets: [
      {
        label: "Phase usage",
        data: activityData.map((item) => item.phaseCount),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Base>
      <div className="dashboard-container">
        <div className="dashboard-content">
          <div className="dashboard-header">
            <Button
              variant="contained"
              onClick={handleBack}
              className="back-button"
            >
              Back
            </Button>
            <Typography
              variant="h4"
              component="div"
              className="dashboard-name"
            >
              {row.FirstName}
            </Typography>
            <Typography
              variant="subtitle2"
              className="dashboard-role"
              style={{
                marginBottom: "16px",
                fontWeight: "bold",
                color: "#666",
              }}
            >
              Role: {getRole(row.RoleId)}
            </Typography>
          </div>

          <div className="dashboard-status-login">
            <Typography
              variant="subtitle1"
              className="dashboard-status"
              style={{
                backgroundColor: getStatusColor(row.Status),
                padding: "4px 5px",
                borderRadius: "12px",
                color: "#fff",
                display: "inline-block",
                marginBottom: "16px",
              }}
            >
              Status: {row.Status}
            </Typography>

            <Typography variant="subtitle1" className="dashboard-last-login">
              Last Login: {formatLastLogin(row.LastLogin)}
            </Typography>
          </div>

          <Card
            className="dashboard-phases"
            sx={{ padding: "16px", marginBottom: "24px" }}
          >
            <Typography variant="h6" gutterBottom>
              Phases Assigned:
            </Typography>
            {row.Phases && row.Phases.length > 0 ? (
              <Grid container spacing={2}>
                {row.Phases.map((phase, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      className="phase-card"
                      sx={{
                        padding: "8px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography variant="body1" className="phase-item">
                        {phase}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body1">No phases assigned</Typography>
            )}
          </Card>

          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography
                variant="h6"
                component="div"
                className="graph-title"
                sx={{ marginTop: 4, marginBottom: 2 }}
              >
                Login Analysis and Phase Usage
              </Typography>
              <Grid container spacing={4}>
                {/* Login Analysis Graph */}
                <Grid item xs={12} sm={6}>
                  <Card className="graph-card" sx={{ padding: "16px" }}>
                    <Typography variant="h6" component="div" className="graph-heading">
                      Login Analysis
                    </Typography>
                    <div className="chart-container">
                      {analysisData.length > 0 ? (
                        <Bar
                          data={loginData}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            scales: {
                              y: {
                                beginAtZero: true,
                                ticks: {
                                  stepSize: 5,
                                },
                              },
                            },
                            plugins: {
                              legend: {
                                display: true,
                                position: "top",
                              },
                            },
                          }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{
                            padding: "16px",
                            textAlign: "center",
                            color: "#999",
                            fontStyle: "italic",
                          }}
                        >
                          Login Analysis data not found
                        </Typography>
                      )}
                    </div>
                  </Card>
                </Grid>

                {/* Phase Usage Graph */}
                <Grid item xs={12} sm={6}>
                  <Card className="graph-card" sx={{ padding: "16px" }}>
                    <Typography variant="h6" component="div" className="graph-heading">
                      Phase Usage
                    </Typography>
                    <div className="chart-container">
                      {activityData.length > 0 ? (
                        <Pie
                          data={phaseData}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                              legend: {
                                display: true,
                                position: "top",
                              },
                            },
                          }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{
                            padding: "16px",
                            textAlign: "center",
                            color: "#999",
                            fontStyle: "italic",
                          }}
                        >
                          Phase Usage data not found
                        </Typography>
                      )}
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </Base>
  );
};

export default DetailsPage;

