import "./Tools.css"

import Base from "../../components/Base/Base"

import defectAnalysis from "../../assets/images/tool_defectAnalysis.svg"
import defectRca from "../../assets/images/tool_defectRca.svg"
import search from "../../assets/images/tools_search.svg"
import estimates from "../../assets/images/tool_time.svg"
import codetobrd from "../../assets/images/tool_codetobrd.svg"
import pluginui from "../../assets/images/tool_pluginui.svg"
import codeconversion from "../../assets/images/tool_codeconversion.svg"
import extractjson from "../../assets/images/tool_extractjson.svg"
import errorfinding from "../../assets/images/tool_finderror.svg"
import resume from "../../assets/images/tool_resume.svg"
import TruBRE from "../../assets/images/Datamatics_KaiBRE_stacked.svg"
import SCA from "../../assets/images/SCA.png"



export default function Tools () {
        return (
                <Base>
                        <div className="Tools_pad">
                                <div className="Tools_container">
                                        <div className="Tools_header">
                                                <div className="Tools_title">ai suite</div>
                                                <div className="Tools_searchContainer">
                                                        <input type="search" className="Tools_search" placeholder="Search tools or categories"/>
                                                        <button className="Tools_searchButton">
                                                                <img src={search} alt="Search"/>
                                                        </button>
                                                </div>
                                        </div>
                                        <div className="Tools_sections">
                                                <div className="Tools_section">
                                                        <div className="Tools_sectionTitle">Legacy Modernization</div>
                                                        <div className="Tools_sectionContent">
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/kaibre"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={TruBRE} alt="Estimates"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">KaiBRE</div>
                                                                                <div className="Tools_feature_description">Extract Business Rules from legacy codebases</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/codetobrd"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={codetobrd} alt="Code to BRD"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Code to BRD</div>
                                                                                <div className="Tools_feature_description">Upload your legacy code file to generate a BRD</div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="Tools_section">
                                                        <div className="Tools_sectionTitle">Development</div>
                                                        <div className="Tools_sectionContent">
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/uidevelopment"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={pluginui} alt="Plugin UI Development"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">UI Development</div>
                                                                                <div className="Tools_feature_description">Get frontend code from webpage pics/figma mockups</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/codeconversion"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={codeconversion} alt="Code Conversion"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Code Conversion</div>
                                                                                <div className="Tools_feature_description">Migrate code from one language to another</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/extractjson"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={extractjson} alt="Extract JSON"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Extract JSON</div>
                                                                                <div className="Tools_feature_description">Retrieve JSON content from webpages</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/errorfinding"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={errorfinding} alt="Estimates"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Error Finding</div>
                                                                                <div className="Tools_feature_description">Find issues in the code, and their solutions</div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="Tools_section">
                                                        <div className="Tools_sectionTitle">Analysis</div>
                                                        <div className="Tools_sectionContent">
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/defectanalysis"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={defectAnalysis} alt="Code to BRD"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Defect Analysis</div>
                                                                                <div className="Tools_feature_description">Pinpoint defects based on historic data</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/defectrca"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={defectRca} alt="Code to BRD"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Defect RCA</div>
                                                                                <div className="Tools_feature_description">Perform Root Cause Analysis to find defects</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/SoftwareCompositionAnalysis"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={SCA} alt="Resume Formatter"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title"> 
                                                                                Software Composition Analysis
                                                                                </div>
                                                                                <div className="Tools_feature_description">Software Component Analysis for Security & Compliance</div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="Tools_section">
                                                        <div className="Tools_sectionTitle">General</div>
                                                        <div className="Tools_sectionContent">
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/estimates"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={estimates} alt="Estimates"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Estimates</div>
                                                                                <div className="Tools_feature_description">Get an estimation on the time benefit offered by TruSDLC</div>
                                                                        </div>
                                                                </div>
                                                                <div className="Tools_feature" onClick={() => window.location.href = "/tools/resumeformatter"}>
                                                                        <div className="Tools_feature_left">
                                                                                <img src={resume} alt="Resume Formatter"/>
                                                                        </div>
                                                                        <div className="Tools_feature_right">
                                                                                <div className="Tools_feature_title">Resume Formatter</div>
                                                                                <div className="Tools_feature_description">Reformat your resume into a high-quality structure</div>
                                                                        </div>
                                                                </div>
                                                              
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </Base>
        )
}