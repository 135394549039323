import "./Pagination.css";
import {useState} from 'react'
// import uparrow from "../../../assets/images/admin/myprofile/Fill-1.svg";
// import downarrow from "../../../assets/images/admin/myprofile/Fill-1-Copy.svg"
// import rightarrow from "../../../assets/images/admin/shareholder/Action.svg";
export default function Pagination(props){
    const [dropdownopen, setdropdownopen] = useState(false)
  
    document.addEventListener('click', function(e) {
      var fails 
      Array.from(document.getElementsByClassName('col2_dropdowncontainer')).forEach(dropdown => {
        if(!dropdown.contains(e.target)) fails = fails + 1;
      })
      if(fails===document.getElementsByClassName('col2_dropdowncontainer').length) setdropdownopen(false)
    })
    
    let {numberOfPages,totalRecords,activePageIndex,updateCurrentPageIndex} = props
    
    return (
        <>
        <div id="tablefooter">
          <div className="tablefooter_col" id="col1">
            Total : {totalRecords} Records
          </div>
          <div className="tablefooter_col" id="col2">
            <div id="col2_lefttext">You are on page</div>
            <div className="col2_dropdowncontainer">
              <div
                className="col2_dropdownselected"
                onClick={() =>{
                  numberOfPages > 1 ?
                  setdropdownopen((prev) => (prev === true ? false : true))
                  :
                  setdropdownopen(false)
                }
                }
              >
                <div id="dropdown-pagenumber">{activePageIndex + 1}</div>
                <img
                  id="dropdown-arrow"
                  // src={dropdownopen ? uparrow : downarrow}
                />
              </div>
              {dropdownopen && <div className="col2_dropdownoptions">
                {[...Array(numberOfPages)].map((x, i) => (
                  <div id="dropdownoption" onClick={() => {updateCurrentPageIndex(i); setdropdownopen(false); }} key={i}>
                    {i + 1}
                  </div>
                ))}
              </div>}
            </div>
            <div id="col2_righttext">of {numberOfPages} pages</div>
          </div>
          <div className="tablefooter_col">
            <div
              className={
                activePageIndex === 0
                  ? "tablefooter_button-hide"
                  : "tablefooter_button"
              }
              id="button1"
              onClick={() => updateCurrentPageIndex((p) => p - 1)}
            >
              <img
                // src={rightarrow}
                className="tablefooter_buttonicon"
                id="leftarrow"
              />
              <div className="tablefooter_buttontitle" id="previous">
                Previous
              </div>
            </div>
            <div
              className={
                (activePageIndex === numberOfPages - 1) || (activePageIndex == 0 && numberOfPages == 0)
                  ? "tablefooter_button-hide"
                  : "tablefooter_button"
              }
              id="button2"
              onClick={() => updateCurrentPageIndex((p) => p + 1)}
            >
              <div className="tablefooter_buttontitle">Next</div>
              <img
                // src={rightarrow}
                className="tablefooter_buttonicon"
                id="rightarrow"
              />
            </div>
          </div>
        </div>
        </>
    )
}