import "./Projects.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Base from "../../components/Base/Base";
import Project from "./Project/Project";

import apple from "../../assets/images/mock_project_logos/Datamatics_logo.png";

import { backendUri } from "../../constants";
import edit from "../../assets/images/editing.png";
import Delete from "../../assets/images/delete.png";
import graph from "../../assets/images/graph_icon.svg"

export default function Projects(props) {
  useEffect(() => sessionStorage.removeItem("phase"), []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const [projects, setProjects] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0)
  const retrieveProjects = () => {
    var myHeaders = new Headers();
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

    var formdata = new FormData();
    formdata.append("isMembers", 1);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: formdata,
      redirect: "follow",
    };

    fetch(`${backendUri}/api/project/get`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setProjects(JSON.parse(result).data.projects)
        JSON.parse(result).data.projects.map(project => setTotalUsers(p => p+project.ProjectManagers.length+project.TeamMembers.length))
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      retrieveProjects();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const [selectedProject, setSelectedProject] = useState(projects[0]);
  useEffect(() => setSelectedProject(projects[0]), [projects]);
  const clickHandler = (id) => {
    setSelectedProject(projects[id]);
  };

  const navigate = useNavigate();
  const handleEditClick = () =>
    navigate("/setup", { state: { selectedProject: selectedProject } });
  const handleDeleteClick = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("profileObj", sessionStorage.getItem("profileObj"));

    // var formdata = new FormData();
    // formdata.append("ProjectId", selectedProject.ProjectId)

    var raw = JSON.stringify({ ProjectId: selectedProject.ProjectId });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      // body: formdata,
      body: raw,
      redirect: "follow",
    };

    fetch(`${backendUri}/api/project/delete`, requestOptions)
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  };
  const goToReprtsPage = () => {
    navigate('/Reports');
  };

  const navigateToUsersList = () => {
    navigate('/UsersList');
  };

  const goToAnalysis = () => {
    navigate("/Analysis", { state: { selectedProject: selectedProject } });
  }


  return (
    <Base>
      <div className="Projects">
        <div className="Projects_container">
          <div className="Projects_left">
            <div className="Projects_label2">
              <div>Assigned projects</div>
              <button className="Projects_totalUsers"  onClick={navigateToUsersList}>Total users: {totalUsers}</button>
              <button className="Projects_totalUsers" onClick={goToReprtsPage}>View Reports</button>

            </div>
            {projects.length ? (
              <div className="Projects_projectsFlex">
                {projects &&
                  projects.map((project, index) =>
                    <Project
                      key={index}
                      logo={apple}
                      name={project.ProjectName}
                      teamSize={project.ProjectManagers.length+project.TeamMembers.length}
                      clickHandler={() => clickHandler(index)}
                    />
                  )}
              </div>
            ) : (
              <div className="Projects_fieldLabel">No projects</div>
            )}
          </div>
          {projects.length ? (
            <div className="Projects_right">
              <div style={{display:'flex',justifyContent:'space-between'}}>
              <div className="Projects_label1">View project</div>
              {/* <button className="custom-button" onClick={goToAnalysis}>
              <span>Analysis</span>
              <img src={graph} alt="Graph Icon" className="custom-icon" />
              </button> */}
              <button className="enhanced-button" onClick={goToAnalysis}>
  <span>Analysis</span>
  <img src={graph} alt="Graph Icon" className="enhanced-icon" />
</button>

              </div>
              <div className="Projects_right_col2">
                <div className="Projects_fieldsRow">
                  <div className="Projects_img">
                    <img
                      className="Projects_projectLogo"
                      src={apple}
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="Projects_fieldsRow">
                  <div className="Projects_fieldBox">
                    <div className="Projects_fieldLabel">Project name</div>

                    <div className="Projects_fieldLabel value">
                      {selectedProject?.ProjectName}
                    </div>
                  </div>
                  <div className="Projects_fieldBox right">
                    <div className="Projects_fieldLabel">Client name</div>
                    <div className="Projects_fieldLabel value">
                      {selectedProject?.ClientName}
                    </div>
                  </div>
                </div>
                <div className="Projects_fieldsRow">
                  <div className="Projects_fieldBox">
                    <div className="Projects_fieldLabel">Project type</div>
                    <div className="Projects_fieldLabel value">
                      {selectedProject?.TypeOfProject}
                    </div>
                  </div>
                  <div className="Projects_fieldBox right">
                    <div className="Projects_fieldLabel">Project duration</div>
                    <div className="Projects_dateRange">
                      <div className="Projects_fieldLabel value">
                        {formatDate(selectedProject?.ProjectDurationStart)}
                      </div>
                      <div className="Projects_fieldLabel value">
                        --to--{formatDate(selectedProject?.ProjectDurationEnd)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Projects_fieldsRow">
                  <div className="Projects_fieldBox">
                    <div className="Projects_fieldLabel">Frontend language</div>
                    <div className="Projects_fieldLabel value">
                      {selectedProject?.FrontendLang}
                    </div>
                  </div>
                  <div className="Projects_fieldBox right">
                    <div className="Projects_fieldLabel">Backend language</div>
                    <div className="Projects_fieldLabel value">
                      {selectedProject?.BackendLang}
                    </div>
                  </div>
                </div>
                <div className="Projects_fieldsRow">
                  <div className="Projects_fieldBox">
                    <div className="Projects_fieldLabel">Collection name</div>
                    <div className="Projects_fieldLabel value">
                      {selectedProject?.CollectionName ?? "NIL"}
                    </div>
                  </div>
                  <div className="Projects_fieldBox right">
                    <div className="Projects_fieldLabel">
                      RAG reference document
                    </div>
                    <div className="Projects_fieldLabel value">
                      {selectedProject?.Reference_doc ?? "NIL"}
                    </div>
                  </div>
                </div>
                <div className="Projects_managers">
                  <div className="Projects_fieldLabel">
                    Project managers list
                  </div>

                  <div className="Projects_membersBox">
                    {selectedProject?.ProjectManagers?.map(
                      (mappedManager, i) => (
                        <div className={`Projects_membersRow`} key={i}>
                          <div className="Projects_fieldBox">
                            <div className={`Projects_member`}>
                              {mappedManager.ProjectManagerName}
                            </div>
                          </div>
                          <div className="Projects_fieldBox right">
                            <div className={`Projects_member`}>
                              {mappedManager.ProjectManagerEmail}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="Projects_team">
                  <div className="Projects_fieldLabel">Team members list</div>
                  <div className="Projects_membersBox">
                    {selectedProject?.TeamMembers?.map((mappedMember, i) => (
                      <div className={`Projects_membersRow`} key={i}>
                        <div className="Projects_fieldBox">
                          <div className={`Projects_member`}>
                            {mappedMember.TeamMemberName}
                          </div>
                        </div>
                        <div className="Projects_fieldBox right">
                          <div className={`Projects_member`}>
                            {mappedMember.TeamMemberEmail}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {sessionStorage.getItem("role") === "1" && (
                  <div className="Projects_right_button">
                    <button className="SN_button" onClick={handleEditClick}>
                      Edit
                      <img src={edit} className="icons" />
                    </button>
                    <button
                      className="SN_button Projects_delete_button"
                      onClick={handleDeleteClick}
                    >
                      Delete
                      <img src={Delete} className="icons" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Base>
  );
}
